<template>
  <div>
    <Header :link-idx="2" />
    <div class="g-min-height mt80 pt30 pb60 w1220">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>下单</el-breadcrumb-item>
      </el-breadcrumb>

      <!-- 一号标书 -->
      <div>
        <div class="line-title fs22 col-3 fwb mtb40">一号标书</div>
        <div class="flex-box mb20">
          <div class="key">标书类型</div>
          <div class="value-box">
            <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand1" @visible-change="toggleDropdown1">
              <div class="el-dropdown-link flex-box" :class="{ 'col-3': bidType1, 'col-9': !bidType1 }">
                <div class="flex-1">{{ bidType1 ? bidType1 : '请选择标书类型' }}</div>
                <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis1 == false, 'el-icon-arrow-up': dropdownVis1 == true }"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="电子标书">电子标书</el-dropdown-item>
                <el-dropdown-item command="纸质标书">纸质标书</el-dropdown-item>
                <el-dropdown-item command="短信标书">短信标书</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="flex-box mb20">
          <div class="key">姓名</div>
          <div class="value-box"><input type="text" v-model="name"   class="mtb20 m-input" placeholder="请输入您的姓名" /></div>
        </div>
        <div class="flex-box mb20">
          <div class="key">身份证</div>
          <div class="value-box"><input type="text" v-model="idnum"   class="mtb20 m-input" placeholder="请输入您的身份证号码" /></div>
        </div>
        <div class="flex-box mb20">
          <div class="key">手机号</div>
          <div class="value-box"><input type="text" v-model="mobile"  class="mtb20 m-input" placeholder="请输入您标书上的手机号" /></div>
        </div>
          <div v-if="showSms" class="flex-box mb20">
          <div class="key">图形验证码</div>
          <div class="value-box flex-box" style="padding: 0 0 0 30px;">
            <input type="text" v-model="captcha"  class="mtb20 m-input flex-1" placeholder="请输入图形验证码" />
            <div class="graphic-code">
              <img :src="url" style="width:100%;height:100%"/>
            </div>
          </div>
        </div>
        <div v-if="showSms" class="flex-box mb20">
          <div class="key">短信验证码</div>
          <div class="value-box flex-box" style="padding: 0 0 0 30px;">
            <input type="text" v-model="smsCode"  class="mtb20 m-input flex-1" placeholder="请输入短信验证码" />
            <div v-show="countShow"  @click="getBidSms()" class="msg-code">获取验证码</div>
            <div v-show="!countShow"  class="msg-code">{{countSecond}}s</div>
          </div>
        </div>
        <div  v-if="!showSms" class="flex-box mb40">
          <div class="key">上传标书</div>
          <div class="upload-wrap flex-box flex-wrap flex-col-start">
            <div v-if="image" class="item">
              <img class="icon-del pointer"  @click="delImage"  src="@/assets/icon/icon_reduce.png" alt="" />
              <img class="icon-pdf" :src="image_url"  alt="" />
            </div>
            <div v-if="!image" class="item">
              <img class="icon-add" src="@/assets/icon/icon_add.png" alt="" />
              <div class="fs16 col-9 tc">上传标书</div>
              <input class="file pointer" type="file" @change="changeFile" />
            </div>
          </div>
        </div>
        <div v-if="showSms"  class="flex-box mb20">
          <div class="key"></div>
          <button  @click="getBidinfo" class="g-btn-tongbu">同步标书</button>
        </div>

      <div v-if="showSms" class="flex-box mb20">
          <div class="key">标书号</div>
          <div class="value-box"><input type="text" v-model="bidCode"  disabled class="mtb20 m-input" placeholder="请点击同步标书获取" /></div>
        </div>
        <div v-if="showSms" class="flex-box mb20">
          <div class="key">标书密码</div>
          <div class="value-box"><input type="text" v-model="bidPwd"  disabled  class="mtb20 m-input" placeholder="请点击同步标书获取" /></div>
        </div>
      </div>

      <!-- 二号标书 -->
      <div>
        <div class="line-title fs22 col-3 fwb mtb40">二号标书</div>
        <div class="flex-box mb20">
          <div class="key">二号标书类型</div>
          <div class="value-box">
            <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand2" @visible-change="toggleDropdown2">
              <div class="el-dropdown-link flex-box" :class="{ 'col-3': bidType2, 'col-9': !bidType2 }">
                <div class="flex-1">{{ bidType2 ? bidType2 : '请选择标书类型' }}</div>
                <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis2 == false, 'el-icon-arrow-up': dropdownVis2 == true }"></i>
              </div>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="电子标书">电子标书</el-dropdown-item>
                <el-dropdown-item command="纸质标书">纸质标书</el-dropdown-item>
                <el-dropdown-item command="短信标书">短信标书</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
        <div class="flex-box mb20">
          <div class="key">姓名</div>
          <div class="value-box"><input type="text"  v-model="name2" class="mtb20 m-input" placeholder="请输入您的姓名" /></div>
        </div>
        <div class="flex-box mb20">
          <div class="key">身份证</div>
          <div class="value-box"><input type="text" v-model="idnum2"  class="mtb20 m-input" placeholder="请输入您的身份证号码" /></div>
        </div>
        <div class="flex-box mb20">
          <div class="key">手机号</div>
          <div class="value-box"><input type="text" v-model="mobile2"   class="mtb20 m-input" placeholder="请输入您标书上的手机号" /></div>
        </div>
          <div  v-if="showSms2"   class="flex-box mb20">
          <div class="key">图形验证码</div>
          <div class="value-box flex-box" style="padding: 0 0 0 30px;">
            <input type="text" v-model="captcha2"  class="mtb20 m-input flex-1" placeholder="请输入图形验证码" />
            <div class="graphic-code">
              <img :src="url2" style="width:100%;height:100%"/>
            </div>
          </div>
        </div>
        <div v-if="showSms2"  class="flex-box mb20">
          <div class="key">短信验证码</div>
          <div class="value-box flex-box" style="padding: 0 0 0 30px;">
            <input type="text" v-model="smsCode2"  class="mtb20 m-input flex-1" placeholder="请输入短信验证码" />
            <div v-show="countShow2"  @click="getBidSms2()" class="msg-code">获取验证码</div>
            <div v-show="!countShow2"  class="msg-code">{{countSecond2}}s</div>
          </div>
        </div>
        <div v-if="!showSms2" class="flex-box mb40">
          <div class="key">上传标书</div>
          <div class="upload-wrap flex-box flex-wrap flex-col-start">
            <div v-if="image2" class="item">
              <img class="icon-del pointer"  @click="delImage2"  src="@/assets/icon/icon_reduce.png" alt="" />
              <img class="icon-pdf" :src="image2_url"  alt="" />
            </div>
            <div v-if="!image2" class="item">
              <img class="icon-add" src="@/assets/icon/icon_add.png" alt="" />
              <div class="fs16 col-9 tc">上传标书</div>
              <input class="file pointer" type="file" @change="changeFile2" />
            </div>
          </div>
        </div>
        <div v-if="showSms2" class="flex-box">
          <div class="key"></div>
          <button @click="getBidinfo2"   class="g-btn-tongbu">同步标书</button>
        </div>
         <div v-if="showSms2" class="flex-box mb20">
          <div class="key">标书号</div>
          <div class="value-box"><input type="text" v-model="bidCode2" disabled   class="mtb20 m-input" placeholder="请点击同步标书获取" /></div>
        </div>
        <div v-if="showSms2" class="flex-box mb20">
          <div class="key">标书密码</div>
          <div class="value-box"><input type="text" v-model="bidPwd2"  disabled class="mtb20 m-input" placeholder="请点击同步标书获取" /></div>
        </div>

      </div>

      <!---<div class="btn-add pointer">添加标书</div>-->

      <div class="line-title fs22 col-3 fwb mtb40">代拍套餐</div>
      <div class="flex-box mb40">
        <div class="key">代拍价格</div>
        <div class="fs16 yellow value-box">
          <!--
          <span class="mr10">*</span>
          <span>10000.00¥</span>
          -->
          <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand3" @visible-change="toggleDropdown3">
            <div class="el-dropdown-link flex-box col-3">
              <div class="flex-1">*{{price}}¥</div>
              <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis3 == false, 'el-icon-arrow-up': dropdownVis3 == true }"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="8888.00">8888.00¥</el-dropdown-item>
              <el-dropdown-item command="11888.00">11888.00¥</el-dropdown-item>
              <el-dropdown-item command="15888.00">15888.00¥</el-dropdown-item>
              <el-dropdown-item command="26888.00">26888.00¥</el-dropdown-item>
              <el-dropdown-item command="6888.00">6888.00¥</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="flex-box mb40">
        <div class="key">代拍次数</div>
        <div class="fs16 yellow value-box">
          <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand4" @visible-change="toggleDropdown4">
            <div class="el-dropdown-link flex-box col-3">
              <div class="flex-1">*{{count}}次</div>
              <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis4 == false, 'el-icon-arrow-up': dropdownVis4 == true }"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="6">6次</el-dropdown-item>
              <el-dropdown-item command="3">3次</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="flex-box">
        <div class="key">赔付金额</div>
        <div class="fs16 yellow">
          <span class="mr10">*</span>
          <span>{{cprice}}¥</span>
        </div>
      </div>
      <div  @click="setOrder" class="btn-sign pointer">签署合同</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'PlaceOrderSecond',
  components: { Header },
  data() {
    return {
      bidType1: '',
      bidType2: '',
      bidText1:'',
      bidText2:'',
      showSms:false,
      showSms2:false,
      price:'8888.00',
      count:6,
      cprice:2588,
      dropdownVis1: false,
      dropdownVis2: false,
      dropdownVis3: false,
      dropdownVis4: false,
      set_id:6,
      checked:false,
      countSecond: 60,
      countShow: true,
      captcha:'',
      smsCode:'',
      sid:'',
      bidcookie:'',
      bidtoken:'',
      url:'',
      captchaUrl:'',
      bidCode:'',
      bidPwd:'',
			name:'',
			idnum:'',
			mobile:'',
			image:'',
			image_url:'/static/images/camera.png',
			meal:[],
			typeIdx: 1,
			typeIdx2: 1,
			name2:'',
			idnum2:'',
			mobile2:'',
			image2:'',
			image_url2:'/static/images/camera.png',
      captcha2:'',
      smsCode2:'',
      sid2:'',
      bidcookie2:'',
      bidtoken2:'',
      url2:'',
      captchaUrl2:'',
      bidCode2:'',
      bidPwd2:'',
      checked2:false,
      countSecond2: 60,
      countShow2: true,
			id:0,
    }
  },
  created(){
    this.token = this.$storage.get('token');
     if(!this.token){
       this.$router.push('/login');
     }
    this.getGpinfo();
    this.getGpinfo2();
  },
  methods: {
    handleCommand1(command) {
      this.bidType1 = command;
      if(this.bidType1=='短信标书'){
           this.showSms = true;
      }else{
            this.showSms = false;
      }
    },
    toggleDropdown1(boolean) {
      this.dropdownVis1 = boolean
    },
    handleCommand2(command) {
      this.bidType2 = command;
      if(this.bidType2=='短信标书'){
           this.showSms2 = true;
      }else{
            this.showSms2 = false;
      }
    },
    toggleDropdown2(boolean) {
      this.dropdownVis2 = boolean
    },
    handleCommand3(command) {
      this.price= command;
      this.getcPrice()
    },
    toggleDropdown3(boolean) {
      this.dropdownVis3 = boolean
    },
    handleCommand4(command) {
      this.count = command;
      this.getcPrice()
    },
    toggleDropdown4(boolean) {
      this.dropdownVis4 = boolean
    },
    getcPrice(){
        var params = {
          	price: this.price,
						count: this.count,
						token: this.token
        };
        this.$http.get("/api/team/findmeal", {
          params
        }).then((res) => {
						if(res.data.code==1){
							this.set_id = res.data.data.id;
							this.cprice =  res.data.data.pei;
						}else{
							this.$message.error(res.data.msg);
							return false;
						}
           
        });     
    },   
    delImage(){
       this.image = '';
    },
    changeFile(e){
            // 上传图像通常要将文件参数放在fromdata 对象中
            // 获取文件信息 e.target.files
            //console.log(e.target.files[0]);
            let formData=new FormData()
            // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
            formData.append('file',e.target.files[0])
            formData.append('token',this.token)
            var loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading'
            });
            this.$http.post('api/common/upload',formData).then(res=>{
                    loading.close();
                    if (res.data.code == 1) {
												this.image = res.data.data.url;
												this.image_url = res.data.data.full_url;
										} else {
                        this.$message.error(res.data.msg);
										}
            })
    },
    delImage2(){
       this.image2 = '';
    },
    changeFile2(e){
            // 上传图像通常要将文件参数放在fromdata 对象中
            // 获取文件信息 e.target.files
            //console.log(e.target.files[0]);
            let formData=new FormData()
            // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
            formData.append('file',e.target.files[0])
            formData.append('token',this.token)
            var loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading'
            });
            this.$http.post('api/common/upload',formData).then(res=>{
                    loading.close();
                    if (res.data.code == 1) {
												this.image2 = res.data.data.url;
												this.image2_url = res.data.data.full_url;
										} else {
                        this.$message.error(res.data.msg);
										}
            })
    },
    setOrder(){
	    var that = this;
			let reg = /^[1][0-9]{10}$/ ;
			let idreg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if(!that.bidType1){
        that.$message.error("请选择标书类型");
				return false;
      }
      if(that.bidType1=='电子标书'){
           that.bidText1 = 1;
      }
      if(that.bidType1=='纸质标书'){
           that.bidText1 = 2;
      }
      if(that.bidType1=='短信标书'){
           that.bidText1 = 3;
      }
			if(!that.name){
        that.$message.error("请输入姓名");
				return false;
			}
			if(!that.idnum){
        that.$message.error("请输入身份证");
				return false;
			}	
		    //判断身份证格式
			if (!idreg.test(that.idnum)) {        
         that.$message.error("身份证号码不正确");
				 return false;
			}
			
			if(!that.mobile){
        that.$message.error("请输入手机号码");
				return false;
			}	
			//判断手机号码格式
			if (!reg.test(that.mobile)) {        
        that.$message.error("手机号码不正确");
				return false;
			}
      if(that.bidText1 == 3){
        if(!that.bidCode){
          that.$message.error("请先获取短信同步标书!");
          return false;
        }
      }

      if(!that.bidType2){
        that.$message.error("请选择二号标书类型");
				return false;
      }
      if(that.bidType2=='电子标书'){
           that.bidText2 = 1;
      }
      if(that.bidType2=='纸质标书'){
           that.bidText2 = 2;
      }
      if(that.bidType2=='短信标书'){
           that.bidText2 = 3;
      }
			if(!that.name2){
        that.$message.error("请输入姓名");
				return false;
			}
			if(!that.idnum2){
        that.$message.error("请输入身份证");
				return false;
			}	
		    //判断身份证格式
			if (!idreg.test(that.idnum2)) {        
         that.$message.error("身份证号码不正确");
				 return false;
			}
			
			if(!that.mobile2){
        that.$message.error("请输入手机号码");
				return false;
			}	
			//判断手机号码格式
			if (!reg.test(that.mobile2)) {        
        that.$message.error("手机号码不正确");
				return false;
			}
      if(that.bidText2 == 3){
        if(!that.bidCode2){
          that.$message.error("请先获取短信同步二号标书!");
          return false;
        }
      }
      that.$storage.set('type',that.bidText1);
      that.$storage.set('name',that.name);
			that.$storage.set('idnum',that.idnum);
      that.$storage.set('mobile',that.mobile);
      that.$storage.set('image',that.image);
      that.$storage.set('bidCode',that.bidCode);
      that.$storage.set('bidPwd',that.bidPwd);

      that.$storage.set('type2',that.bidText2);
      that.$storage.set('name2',that.name2);
			that.$storage.set('idnum2',that.idnum2);
      that.$storage.set('mobile2',that.mobile2);
      that.$storage.set('image2',that.image2);
      that.$storage.set('bidCode2',that.bidCode2);
      that.$storage.set('bidPwd2',that.bidPwd2);
      that.$router.push({ path: '/signContract',query:{id:that.set_id,type:1} })
    },
    getGpinfo(){
        var that =  this;
        var params = {token:that.token};
        that.$http.get("/api/common/getGpinfo", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.sid = res.data.data.sid;
                that.bidcookie = res.data.data.bidcookie;
                that.bidtoken = res.data.data.bidtoken;
                that.captchaUrl = res.data.data.url;
                that.url = res.data.data.url;
            }
        });           
    },
    getBidSms(){
        var that =  this;
        if(!that.captcha){
          that.$message.error("请输入图形验证码");
          return false;
        }
        if(!that.mobile){
            that.$message.error("请输入手机号码");
            return false;
        }
        let reg = /^[1][0-9]{10}$/ ;
        if (!reg.test(that.mobile)) {        
          that.$message.error("手机号码不正确");
          return false;
        }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
        var params = {sid:that.sid, captcha:that.captcha,mobile:that.mobile, token:that.token};
        that.$http.get("/api/common/getBidSms", {
          params
        }).then((res) => {
            loading.close();
           if(res.data.code==1){
              that.$message.success(res.data.msg);
            var t;
            var countSecond = that.countSecond;
            var countShow = that.countShow;
            that.countShow = countShow
            t = setInterval(function () {
              countSecond--
              if (countSecond <= 0) {
                countShow = true
                clearInterval(t)
                countSecond = 60
              } else {
                countShow = false
                if (countSecond < 10) {
                  countSecond = '0' + countSecond
                }
              }
              that.countSecond = countSecond
              that.countShow = countShow
            }, 1000);
           }else{
              that.$message.error(res.data.msg);
           }
        });   
    },
    getBidinfo(){
        var that =  this;
        if(!that.captcha){
          that.$message.error("请输入图形验证码");
          return false;
        }
        if(!that.mobile){
            that.$message.error("请输入手机号码");
            return false;
        }
        let reg = /^[1][0-9]{10}$/ ;
        if (!reg.test(that.mobile)) {        
          that.$message.error("手机号码不正确");
          return false;
        }
        let idreg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if(!that.idnum){
          that.$message.error("请输入身份证");
          return false;
        }	
          //判断身份证格式
        if (!idreg.test(that.idnum)) {        
          that.$message.error("身份证号码不正确");
          return false;
        }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
        var params = {
            sid:that.sid, 
            captcha:that.captcha,
            mobile:that.mobile,
            certId:that.idnum,
            bidtoken: that.bidtoken,
            cookie: that.bidcookie,
            smsCode: that.smsCode,
            token:that.token
            };
        that.$http.get("/api/common/getBidInfo", {
          params
        }).then((res) => {
            loading.close();
            console.log(res.data.data);
           if(res.data.data.bidCode){
              that.$message.success('获取成功!');
              that.bidCode = res.data.data.bidCode;
              that.bidPwd = res.data.data.bidPwd;
           }else{
              that.$message.error('获取失败,请重新输入!');
              that.smsCode = '';
              that.captcha= '';
              that.changeurl();
           }
        });   
    },
    getGpinfo2(){
        var that =  this;
        var params = {token:that.token};
        that.$http.get("/api/common/getGpinfo", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.sid2 = res.data.data.sid;
                that.bidcookie2 = res.data.data.bidcookie;
                that.bidtoken2 = res.data.data.bidtoken;
                that.captchaUrl2 = res.data.data.url;
                that.url2 = res.data.data.url;
            }
        });           
    },
    getBidSms2(){
        var that =  this;
        if(!that.captcha2){
          that.$message.error("请输入图形验证码");
          return false;
        }
        if(!that.mobile2){
            that.$message.error("请输入手机号码");
            return false;
        }
        let reg = /^[1][0-9]{10}$/ ;
        if (!reg.test(that.mobile2)) {        
          that.$message.error("手机号码不正确");
          return false;
        }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
        var params = {sid:that.sid2, captcha:that.captcha2,mobile:that.mobile2, token:that.token};
        that.$http.get("/api/common/getBidSms", {
          params
        }).then((res) => {
            loading.close();
           if(res.data.code==1){
              that.$message.success(res.data.msg);
            var t;
            var countSecond = that.countSecond2;
            var countShow = that.countShow2;
            that.countShow2 = countShow
            t = setInterval(function () {
              countSecond--
              if (countSecond <= 0) {
                countShow = true
                clearInterval(t)
                countSecond = 60
              } else {
                countShow = false
                if (countSecond < 10) {
                  countSecond = '0' + countSecond
                }
              }
              that.countSecond2 = countSecond
              that.countShow2 = countShow
            }, 1000);
           }else{
              that.$message.error(res.data.msg);
           }
        });   
    },
    getBidinfo2(){
        var that =  this;
        if(!that.captcha2){
          that.$message.error("请输入图形验证码");
          return false;
        }
        if(!that.mobile2){
            that.$message.error("请输入手机号码");
            return false;
        }
        let reg = /^[1][0-9]{10}$/ ;
        if (!reg.test(that.mobile2)) {        
          that.$message.error("手机号码不正确");
          return false;
        }
        let idreg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if(!that.idnum2){
          that.$message.error("请输入身份证");
          return false;
        }	
          //判断身份证格式
        if (!idreg.test(that.idnum2)) {        
          that.$message.error("身份证号码不正确");
          return false;
        }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
        var params = {
            sid:that.sid2, 
            captcha:that.captcha2,
            mobile:that.mobile2,
            certId:that.idnum2,
            bidtoken: that.bidtoken2,
            cookie: that.bidcookie2,
            smsCode: that.smsCode2,
            token:that.token
            };
        that.$http.get("/api/common/getBidInfo", {
          params
        }).then((res) => {
            loading.close();
            console.log(res.data.data);
           if(res.data.data.bidCode){
              that.$message.success('获取成功!');
              that.bidCode2 = res.data.data.bidCode;
              that.bidPwd2 = res.data.data.bidPwd;
           }else{
              that.$message.error('获取失败,请重新输入!');
              that.smsCode2 = '';
              that.captcha2= '';
              that.changeurl2();
           }
        });   
    },
    changeurl(){
      this.url = this.captchaUrl+'&r='+Math.random();
    },
    changeurl2(){
      this.url2 = this.captchaUrl2+'&r='+Math.random();
    },
  }
}
</script>
<style lang="less" scoped>
.team {
  .img-head {
    display: block;
    width: 102px;
    height: 102px;
    border-radius: 50%;
  }
  .team-label {
    width: 240px;
    height: 30px;
    background: #d4f2fc;
    border-radius: 4px;
    font-size: 16px;
    color: #0a9bb8;
    line-height: 30px;
    text-align: center;
  }
}
.line-title {
  position: relative;
  padding-left: 26px;
}
.line-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 6px;
  height: 30px;
  background: var(--yellow);
}
.key {
  width: 160px;
  font-size: 18px;
  color: #333333;
  line-height: 22px;
}
.value-box {
  padding: 0 30px;
  width: 1060px;
  height: 70px;
  background: #f6f6f6;
  border-radius: 10px;
  .el-dropdown {
    display: block;
    height: 70px;
  }
  .el-dropdown-link {
    height: 70px;
    font-size: 16px;
    line-height: 70px;
  }
  .m-input {
    font-size: 16px;
    line-height: 30px;
  }
  .graphic-code{
    width: 110px;
    height: 70px;
    background: #D4F2FC;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
  }
  .msg-code{
    width: 110px;
    height: 70px;
    background: #F6E65F;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
    line-height: 70px;
    font-size: 14px;
    cursor: pointer;
    color: #333333;
  }
}
.el-dropdown-menu {
  width: 1000px;
}

.upload-wrap {
  .item {
    position: relative;
    margin: 0 15px 0 0;
    padding: 27px 0 0;
    width: 106px;
    height: 106px;
    background: #fafafa;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    .icon-del {
      position: absolute;
      right: -11px;
      top: -11px;
      display: block;
      width: 22px;
      height: 22px;
    }
    .icon-pdf {
      margin: 0 auto;
      display: block;
      width: 51px;
      height: 53px;
    }
    .icon-add {
      margin: 0 auto 10px;
      display: block;
      width: 27px;
      height: 27px;
    }
    .file {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
}
.btn-sign {
  margin: 100px auto 0;
  display: block;
  width: 300px;
  height: 70px;
  background: #f6e65f;
  border-radius: 10px 10px 10px 10px;
  font-size: 20px;
  color: #333333;
  line-height: 70px;
  text-align: center;
}
.btn-add {
  margin: 80px auto 0;
  display: block;
  width: 300px;
  height: 70px;
  background: #F0C96E;
  border-radius: 10px;
  font-size: 20px;
  color: #FFFFFF;
  line-height: 70px;
  text-align: center;
}
</style>
