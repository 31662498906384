<template>
  <div>
    <Header :link-idx="2" />
    <div class="g-min-height mt80 pt30 pb60 w1220">
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/team' }">团队</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/teamDetail' ,query:{ id:team_id} }">团队详情</el-breadcrumb-item>
        <el-breadcrumb-item>下单</el-breadcrumb-item>
      </el-breadcrumb>
      <div class="team ptb40 flex-box m-hairline--bottom">
        <img class="img-head mr30" :src="team.logo_image_url" alt="" />
        <div class="flex-1">
          <div class="fs30 col-3 mb20">{{team.name}}</div>
          <div class="team-label">{{team.label}}</div>
        </div>
      </div>
      <!--
      <div class="line-title fs22 col-3 fwb mtb40">一号标书</div>-->
      <div class="flex-box mb20">
        <div class="key">标书类型</div>
        <div class="value-box">
          <el-dropdown class="dropdown pointer" placement="bottom-start" trigger="click" @command="handleCommand1" @visible-change="toggleDropdown1">
            <div class="el-dropdown-link flex-box" :class="{ 'col-3': bidType1, 'col-9': !bidType1 }">
              <div class="flex-1">{{ bidType1 ? bidType1 : '请选择标书类型' }}</div>
              <i class="el-icon--right" :class="{ 'el-icon-arrow-down': dropdownVis1 == false, 'el-icon-arrow-up': dropdownVis1 == true }"></i>
            </div>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="电子标书">电子标书</el-dropdown-item>
              <el-dropdown-item command="纸质标书">纸质标书</el-dropdown-item>
              <el-dropdown-item command="短信标书">短信标书</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div class="flex-box mb20">
        <div class="key">姓名</div>
        <div class="value-box"><input type="text" v-model="name"  class="mtb20 m-input" placeholder="请输入您的姓名" /></div>
      </div>
      <div class="flex-box mb20">
        <div class="key">身份证</div>
        <div class="value-box"><input type="text" v-model="idnum"  class="mtb20 m-input" placeholder="请输入您的身份证号码" /></div>
      </div>
      <div class="flex-box mb20">
        <div class="key">手机号</div>
        <div class="value-box"><input type="text" v-model="mobile"  class="mtb20 m-input" placeholder="请输入您标书上的手机号" /></div>
      </div>
      <div v-if="showSms" class="flex-box mb20">
        <div class="key">图形验证码</div>
        <div class="value-box flex-box" style="padding: 0 0 0 30px;">
          <input type="text" v-model="captcha"  class="mtb20 m-input flex-1" placeholder="请输入图形验证码" />
          <div class="graphic-code">
            <img :src="url" style="width:100%;height:100%"/>
          </div>
        </div>
      </div>
      <div v-if="showSms" class="flex-box mb20">
        <div class="key">短信验证码</div>
        <div class="value-box flex-box" style="padding: 0 0 0 30px;">
          <input type="text" v-model="smsCode"  class="mtb20 m-input flex-1" placeholder="请输入短信验证码" />
          <div v-show="countShow"  @click="getBidSms()" class="msg-code">获取验证码</div>
          <div v-show="!countShow"  class="msg-code">{{countSecond}}s</div>
        </div>
      </div>
      <div v-if="!showSms" class="flex-box mb40">
        <div class="key">上传标书</div>
        <div class="upload-wrap flex-box flex-wrap flex-col-start">
          <div v-if="image" class="item">
            <img class="icon-del pointer"  @click="delImage"  src="@/assets/icon/icon_reduce.png" alt="" />
            <img class="icon-pdf" :src="image_url" @click="changeurl"  alt="" />
          </div>
          <div v-if="!image" class="item">
            <img class="icon-add" src="@/assets/icon/icon_add.png" alt="" />
            <div class="fs16 col-9 tc">上传标书</div>
            <input class="file pointer" type="file" @change="changeFile" />
          </div>
        </div>
      </div>

      <div v-if="showSms"  class="flex-box mb20">
        <div class="key"></div>
        <button  @click="getBidinfo" class="g-btn-tongbu">同步标书</button>
      </div>

     <div v-if="showSms" class="flex-box mb20">
        <div class="key">标书号</div>
        <div class="value-box"><input type="text" v-model="bidCode"  disabled class="mtb20 m-input" placeholder="请点击同步标书获取" /></div>
      </div>
      <div v-if="showSms" class="flex-box mb20">
        <div class="key">标书密码</div>
        <div class="value-box"><input type="text" v-model="bidPwd"  disabled  class="mtb20 m-input" placeholder="请点击同步标书获取" /></div>
      </div>

      <div class="line-title fs22 col-3 fwb mtb40">代拍套餐</div>
      <div class="flex-box mb40">
        <div class="key">代拍价格</div>
        <div class="fs16 yellow">
          <span class="mr10">*</span>
          <span>{{meal.price}}¥</span>
        </div>
      </div>
      <div class="flex-box mb40">
        <div class="key">代拍次数</div>
        <div class="fs16 yellow">
          <span class="mr10">*</span>
          <span>{{meal.frequency}}次</span>
        </div>
      </div>
      <div class="flex-box">
        <div class="key">赔付金额</div>
        <div class="fs16 yellow">
          <span class="mr10">*</span>
          <span>{{meal.compensation_price}}¥</span>
        </div>
      </div>
      <div @click="setOrder" class="btn-sign pointer">签署合同</div>
    </div>
  </div>
</template>
<script>
import Header from '@/components/header/Header.vue'
export default {
  name: 'PlaceOrder',
  components: { Header },
  data() {
    return {
      bidType1: '',
      bidType2: '',
      dropdownVis1: false,
      dropdownVis2: false,
      showSms:false,
      id:0,
      team_id:0,
      team:[],
      meal:[],
      bidText1:1,
      captcha:'',
      smsCode:'',
      sid:'',
      bidcookie:'',
      bidtoken:'',
      url:'',
      captchaUrl:'',
			name:'',
			idnum:'',
			mobile:'',
			image:'',
      bidCode:'',
      bidPwd:'',
      checked:false,
      countSecond: 60,
      countShow: true,
			image_url:'/static/images/camera.png',

    }
  },
  created(){
    this.token = this.$storage.get('token');
    this.id = this.$route.query.id ;
     if(!this.token){
       this.$router.push('/login');
       return false;
     }
     this.getGpinfo();
  },
  mounted() {
    this.getMeal();
    this.getMealTeam();
    scrollTo({
      top: 0
    })
  },
  methods: {
    handleCommand1(command) {
      this.bidType1 = command;
      if(this.bidType1=='短信标书'){
           this.showSms = true;
      }else{
            this.showSms = false;
      }
    },
    toggleDropdown1(boolean) {
      this.dropdownVis1 = boolean
    },
    handleCommand2(command) {
      this.bidType2 = command
    },
    toggleDropdown2(boolean) {
      this.dropdownVis2 = boolean
    },
    changeurl(){
      this.url = this.captchaUrl+'&r='+Math.random();
    },
    getGpinfo(){
        var that =  this;
        var params = {token:that.token};
        that.$http.get("/api/common/getGpinfo", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.sid = res.data.data.sid;
                that.bidcookie = res.data.data.bidcookie;
                that.bidtoken = res.data.data.bidtoken;
                that.captchaUrl = res.data.data.url;
                that.url = res.data.data.url;
            }
        });           
    },
    getBidSms(){
        var that =  this;
        if(!that.captcha){
          that.$message.error("请输入图形验证码");
          return false;
        }
        if(!that.mobile){
            that.$message.error("请输入手机号码");
            return false;
        }
        let reg = /^[1][0-9]{10}$/ ;
        if (!reg.test(that.mobile)) {        
          that.$message.error("手机号码不正确");
          return false;
        }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
        var params = {sid:that.sid, captcha:that.captcha,mobile:that.mobile, token:that.token};
        that.$http.get("/api/common/getBidSms", {
          params
        }).then((res) => {
            loading.close();
           if(res.data.code==1){
              that.$message.success(res.data.msg);
            var t;
            var countSecond = that.countSecond;
            var countShow = that.countShow;
            that.countShow = countShow
            t = setInterval(function () {
              countSecond--
              if (countSecond <= 0) {
                countShow = true
                clearInterval(t)
                countSecond = 60
              } else {
                countShow = false
                if (countSecond < 10) {
                  countSecond = '0' + countSecond
                }
              }
              that.countSecond = countSecond
              that.countShow = countShow
            }, 1000);
           }else{
              that.$message.error(res.data.msg);
           }
        });   
    },
    getBidinfo(){
        var that =  this;
        if(!that.captcha){
          that.$message.error("请输入图形验证码");
          return false;
        }
        if(!that.mobile){
            that.$message.error("请输入手机号码");
            return false;
        }
        let reg = /^[1][0-9]{10}$/ ;
        if (!reg.test(that.mobile)) {        
          that.$message.error("手机号码不正确");
          return false;
        }
        let idreg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
        if(!that.idnum){
          that.$message.error("请输入身份证");
          return false;
        }	
          //判断身份证格式
        if (!idreg.test(that.idnum)) {        
          that.$message.error("身份证号码不正确");
          return false;
        }
       var loading = that.$loading({
          lock: true,
          text: 'Loading',
          spinner: 'el-icon-loading'
       });
        var params = {
            sid:that.sid, 
            captcha:that.captcha,
            mobile:that.mobile,
            certId:that.idnum,
            bidtoken: that.bidtoken,
            cookie: that.bidcookie,
            smsCode: that.smsCode,
            token:that.token
            };
        that.$http.get("/api/common/getBidInfo", {
          params
        }).then((res) => {
            loading.close();
            console.log(res.data.data);
           if(res.data.data.bidCode){
              that.$message.success('获取成功!');
              that.bidCode = res.data.data.bidCode;
              that.bidPwd = res.data.data.bidPwd;
           }else{
              that.$message.error('获取失败,请重新输入!');
              that.smsCode = '';
              that.captcha= '';
              that.changeurl();
           }
        });   
    },
    getMeal(){
         var that =  this;
        var params = {id:that.id,token:that.token};
        that.$http.get("/api/team/mealdetail", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.meal = res.data.data;
            }else{
              setTimeout(function(){
                //跳转首页
                that.$router.push('/');
              },1500);
            }
        });           
    },
    getMealTeam(){
        var that =  this;
        var params = {id:that.id,token:that.token};
        that.$http.get("/api/team/mealteam", {
          params
        }).then((res) => {
            if(res.data.code==1){
                that.team = res.data.data;
                that.team_id = res.data.data.id;
            }else{
              setTimeout(function(){
                //跳转首页
                that.$router.push('/');
              },1500);
            }
        });           
    },
    delImage(){
       this.image = '';
    },
    changeFile(e){
            // 上传图像通常要将文件参数放在fromdata 对象中
            // 获取文件信息 e.target.files
            //console.log(e.target.files[0]);
            let formData=new FormData()
            // 向formData实例中追加属性 (参数1：属性名 参数2：属性值)
            formData.append('file',e.target.files[0])
            formData.append('token',this.token)
            var loading = this.$loading({
                lock: true,
                text: 'Loading',
                spinner: 'el-icon-loading'
            });
            this.$http.post('api/common/upload',formData).then(res=>{
                    loading.close();
                    if (res.data.code == 1) {
												this.image = res.data.data.url;
												this.image_url = res.data.data.full_url;
										} else {
                        this.$message.error(res.data.msg);
										}
            })
    },
    setOrder(){
	    var that = this;
			let reg = /^[1][0-9]{10}$/ ;
			let idreg = /^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;
      if(!that.bidType1){
        that.$message.error("请选择标书类型");
				return false;
      }
      if(that.bidType1=='电子标书'){
           that.bidText1 = 1;
      }
      if(that.bidType1=='纸质标书'){
           that.bidText1 = 2;
      }
      if(that.bidType1=='短信标书'){
           that.bidText1 = 3;
      }
			if(!that.name){
        that.$message.error("请输入姓名");
				return false;
			}
			if(!that.idnum){
        that.$message.error("请输入身份证");
				return false;
			}	
		    //判断身份证格式
			if (!idreg.test(that.idnum)) {        
         that.$message.error("身份证号码不正确");
				 return false;
			}
			
			if(!that.mobile){
        that.$message.error("请输入手机号码");
				return false;
			}	
			//判断手机号码格式
			if (!reg.test(that.mobile)) {        
        that.$message.error("手机号码不正确");
				return false;
			}
      if(that.bidText1 == 3){
        if(!that.bidCode){
          that.$message.error("请先获取短信同步标书!");
          return false;
        }
      }
      that.$storage.set('type',that.bidText1);
      that.$storage.set('name',that.name);
			that.$storage.set('idnum',that.idnum);
      that.$storage.set('mobile',that.mobile);
      that.$storage.set('image',that.image);
      that.$storage.set('bidCode',that.bidCode);
      that.$storage.set('bidPwd',that.bidPwd);
      that.$router.push({ path: '/signContract',query:{id:that.id,type:0} })
    }
  }
}
</script>
<style lang="less" scoped>
.team {
  .img-head {
    display: block;
    width: 102px;
    height: 102px;
    border-radius: 50%;
  }
  .team-label {
    width: 240px;
    height: 30px;
    background: #d4f2fc;
    border-radius: 4px;
    font-size: 16px;
    color: #0a9bb8;
    line-height: 30px;
    text-align: center;
  }
}
.line-title {
  position: relative;
  padding-left: 26px;
}
.line-title::before {
  content: '';
  position: absolute;
  left: 0;
  top: 2px;
  width: 6px;
  height: 30px;
  background: var(--yellow);
}
.key {
  width: 160px;
  font-size: 18px;
  color: #333333;
  line-height: 22px;
}
.value-box {
  padding: 0 30px;
  width: 1060px;
  height: 70px;
  background: #f6f6f6;
  border-radius: 10px;
  .el-dropdown {
    display: block;
    height: 70px;
  }
  .el-dropdown-link {
    height: 70px;
    font-size: 16px;
    line-height: 70px;
  }
  .m-input {
    font-size: 16px;
    line-height: 30px;
  }
  .graphic-code{
    width: 110px;
    height: 70px;
    background: #D4F2FC;
    border-radius: 10px 10px 10px 10px;
    cursor: pointer;
  }
  .msg-code{
    width: 110px;
    height: 70px;
    background: #F6E65F;
    border-radius: 10px 10px 10px 10px;
    text-align: center;
    line-height: 70px;
    font-size: 14px;
    cursor: pointer;
    color: #333333;
  }
}

.el-dropdown-menu {
  width: 1000px;
}

.upload-wrap {
  .item {
    position: relative;
    margin: 0 15px 0 0;
    padding: 27px 0 0;
    width: 106px;
    height: 106px;
    background: #fafafa;
    border: 1px solid #ebebeb;
    border-radius: 10px;
    .icon-del {
      position: absolute;
      right: -11px;
      top: -11px;
      display: block;
      width: 22px;
      height: 22px;
    }
    .icon-pdf {
      margin: 0 auto;
      display: block;
      width: 51px;
      height: 53px;
    }
    .icon-add {
      margin: 0 auto 10px;
      display: block;
      width: 27px;
      height: 27px;
    }
    .file {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      opacity: 0;
    }
  }
}
.btn-sign {
  margin: 100px auto 0;
  display: block;
  width: 300px;
  height: 70px;
  background: #f6e65f;
  border-radius: 10px 10px 10px 10px;
  font-size: 20px;
  color: #333333;
  line-height: 70px;
  text-align: center;
}
</style>
